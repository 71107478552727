<template>
	<div class="container">
		<div class="header">
			<img src="../../assets/imgs/jm.png" alt="">
			<div class="text">
				<p class="title">天乾地坤合作伙伴招募中</p>
				<p class="subTitle">与天乾地坤一路同行，共同成长</p>
				<p class="btn"  @click="dialog = true">立即申请</p>
			</div>
		</div>
		
		<div class="advantage">
			<p class="title">我们的优势</p>
			<div class="active-div">
				<p @click="handleActiveClick(item.id)" :class="{'active': active == item.id}" v-for="item of adArr" :key="item.id">
					{{item.name}}
				</p>
			</div>
			
			<div class="img-div">
				<img src="../../assets/imgs/jm1.png" alt="">
				<p v-show="active == 1" class="paas">支持paas服务模式</p>
				<p v-show="active == 1" class="money">按需计费</p>
				<p v-show="active == 1" class="fw">可按需购买所需服务</p>
				<p v-show="active == 1" class="bs">支快速部署</p>
				<div v-show="active == 1" class="point1"></div>
				<div v-show="active == 1" class="point2"></div>
				<div v-show="active == 1" class="point3"></div>
				<div v-show="active == 1" class="point4"></div>
				<p v-show="active == 2" class="wifi">基于Zigbee及WIFI的无线技术</p>
				<p v-show="active == 2" class="nandu">可有效降低施工难度</p>
				<p v-show="active == 2" class="tiyan">同时提高用户体验性</p>
				<div v-show="active == 2" class="point5"></div>
				<div v-show="active == 2" class="point6"></div>
				<div v-show="active == 2" class="point7"></div>
				<p v-show="active == 3" class="xieyi">兼容多种控制协议</p>
				<p v-show="active == 3" class="kongzhi">主机支持动态添加控制设备</p>
				<div v-show="active == 3" class="point8"></div>
				<div v-show="active == 3" class="point9"></div>
				<p v-show="active == 4" class="phone">用户可以通过用手机端进行控制</p>
				<div v-show="active == 4" class="point10"></div>
			</div>
		</div>
		
		<div class="process">
			<p class="title">天乾地坤合作伙伴申请流程</p>
			<div class="img-div1">
				<div class="img-cont">
					<div class="img-text" v-for="item of imgArr" :key="item.id">
						<img :src="item.img" alt="">
						<p class="name">{{item.name}}</p>
					</div>
				</div>
				<img class="jm6-1" src="../../assets/imgs/jm6.png" alt="">
				<img class="jm6-2"  src="../../assets/imgs/jm6.png" alt="">
				<img class="jm6-3"  src="../../assets/imgs/jm6.png" alt="">
			</div>
			
			<div class="btns">
				<p @click="dialog = true">立即申请</p>
			</div>
		</div>
		
		<div class="apply">
			<img src="../../assets/imgs/apply.png" alt="">
			<div class="text">
				<p class="title">申请合作</p>
				<div>
					<el-descriptions :column="2">
					    <el-descriptions-item label="公司名称">天乾地坤科技有限股份公司</el-descriptions-item>
					    <el-descriptions-item label="联系电话">400-066-1909</el-descriptions-item>
					    <el-descriptions-item label="邮箱">duan.liu@cklock.cn</el-descriptions-item>
					    <el-descriptions-item label="地址">河北省承德市双滦区君泰财富广场A区</el-descriptions-item>
					</el-descriptions>
				</div>
			</div>
		</div>
		
		<el-dialog title="申请合作" :visible.sync="dialog" width="900px">
			
			<el-form :model="dialogForm" style="display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap;" label-width="100px">
				<el-form-item label="公司名称:">
					<el-input v-model="dialogForm.name" style="width: 300px;" placeholder="请输入公司名称"></el-input>
				</el-form-item>
				<el-form-item label="所在城市:">
					<el-input v-model="dialogForm.city" style="width: 300px;" placeholder="请输入公司名称"></el-input>
				</el-form-item>
				<el-form-item label="联系人:">
					<el-input v-model="dialogForm.contact" style="width: 300px;" placeholder="请输入公司名称"></el-input>
				</el-form-item>
				<el-form-item label="联系电话:">
					<el-input v-model="dialogForm.phone" style="width: 300px;" placeholder="请输入公司名称"></el-input>
				</el-form-item>
			</el-form>
			
			<div slot="footer" class="dialog-footer">
			    <el-button @click="dialog = false">取 消</el-button>
			    <el-button type="primary" @click="dialog = false">确 定</el-button>
			  </div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				adArr: [
					{
						id: '1',
						name: '云平台'
					},
					{
						id: '2',
						name: '无限组网'
					},
					{
						id: '3',
						name: '动态扩展'
					},
					{
						id: '4',
						name: '移动端控制'
					},
				],
				active: '1',
				imgArr: [
					{
						id: '1',
						name: '提交申请',
						img: require('@/assets/imgs/jm2.png')
					},
					{
						id: '2',
						name: '审核通过',
						img: require('@/assets/imgs/jm2.png')
					},
					{
						id: '3',
						name: '洽谈合作方案',
						img: require('@/assets/imgs/jm2.png')
					},
					{
						id: '4',
						name: '达成合作',
						img: require('@/assets/imgs/jm2.png')
					},
				],
				dialog: false,
				dialogForm: {
					name: '',
					city: '',
					contact: '',
					phone: ''
				}
			}
		},
		
		methods: {
			handleActiveClick(val) {
				this.active = val
			}
		}
	}
</script>

<style scoped>
.header {
	position: relative;
}

.header img {
	width: 100%;
}

.header .text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.header .text .title {
	height: 48px;
	font-size: 48px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #FFFFFF;
}

.header .text .subTitle {
	height: 28px;
	font-size: 28px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #FFFFFF;
	margin: 19px 0 45px;
}

.header .text .btn {
	width: 168px;
	height: 60px;
	background: #FFFFFF;
	line-height: 60px;
	font-size: 24px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #184BAB;
	text-align: center;
	cursor: pointer;
}

.advantage {
	padding-top: 149px;
	text-align: center;
}

.advantage .title {
	height: 26px;
	font-size: 26px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
}

.advantage .active-div {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 28px 0 76px;
}

.advantage .active-div p {
	width: 71px;
	height: 26px;
	font-size: 14px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;
	line-height: 26px;
	text-align: center;
	cursor: pointer;
}

.advantage .active-div .active {
	border: 1px solid #184BAB;
	color: #184BAB;
}

.advantage .active-div p:not(:last-child) {
	margin-right: 38px;
}

.img-div {
	width: 682px;
	height: 436px;
	position: relative;
	left: 50%;
	transform: translate(-50%,0);
}

.img-div img {
	width: 100%;
	height: 100%;
}

.img-div p {
	height: 14px;
	font-size: 14px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;
	position: absolute;
}

.img-div div {
	width: 11px;
	height: 11px;
	background: #184BAB;
	border-radius: 50%;
	position: absolute;
}

.paas {
	top: 134px;
	left: -36px;
}

.money {
	top: 101px;
	left: 541px;
}

.bs {
	bottom: 56px;
	right: 0;
}

.fw {
		bottom: -2px;
		left: 166px;
}

.point1 {
	top: 134px;
	right: 162px;
}
.point2 {
	top: 163px;
	left: 82px;
}
.point3 {
	top: 386px;
	right: 516px;
}
.point4 {
	top: 355px;
	right: 93px;
}

.wifi {
	top: 169px;
	left: -121px;
}

.nandu {
	top: 197px;
	right: -99px;
}

.tiyan {
	bottom: 0;
	right: 193px;
}
.point5 {
	top: 229px;
	right: 32px;
}
.point6 {
	top: 197px;
	left: 36px;
}
.point7 {
	bottom: 14px;
	right: 347px;
}

.xieyi {
	top: 197px;
	right: -99px;
}

.kongzhi {
	top: 373px;
	left: -60px;
}
.point8 {
	top: 347px;
	left: 70px;
}
.point9 {
	top: 229px;
	right: 32px;
}

.phone {
	bottom: 10px;
	left: 45px;
}

.point10 {
	top: 388px;
	left: 175px;
}

.process {
	padding: 187px 0 90px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.process .title {
	height: 26px;
	font-size: 26px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
}

.process .img-div1 {
	width: 888px;
	height: 216px;
	background: #F4F8FF;
	border-radius: 66px;
	margin-top: 113px;
	position: relative;
}

.process .img-div1 .img-cont {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	top: -58px;
}

.process .img-div1 .img-cont .name {
	height: 18px;
	font-size: 18px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #666666;
	margin-top: 14px;
}

.jm6-1 {
	position: absolute;
	bottom: 67px;
	left: 145px;
}
.jm6-2 {
	position: absolute;
	bottom: 67px;
	left: 385px;
}
.jm6-3 {
	position: absolute;
	bottom: 67px;
	right: 135px;
}

.btns {
	margin: 28px 0 90px;
	display: flex;
	justify-content: center;
}

.btns p {
	width: 135px;
	height: 42px;
	background: linear-gradient(118deg, #1B50B1 0%, #4782EE 100%);
	border-radius: 29px;
	line-height: 42px;
	text-align: center;
	color: #fff;
	cursor: pointer;
}

.apply {
	position: relative;
	text-align: center;
}

.apply  img {
	width: 100%;
	height: 312px;
}
.apply .text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.apply .title {
	height: 23px;
	font-size: 23px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: bold;
	color: #333333
}

.apply div {
	margin-top: 42px;
	
}

.el-descriptions ::v-deep .el-descriptions__body{
	background: none;
	font-size: 20px;
}
</style>